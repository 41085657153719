import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { Provider } from '@angular/core';

import {
  AutoridadHttpService,
  ContactoHttpService,
  MenuHttpService,
  NoticiaHttpService,
  PaginaHttpService,
  ProgramaHttpService,
  RedSocialHttpService,
  RevistaHttpService,
  RutaHttpService,
  ServicioHttpService,
  SliderHttpService,
  TesisHttpService,
  TransparenciaHttpService,
} from '@core/http';
import { ApiInterceptor } from '@core/interceptors';
import { RouteResolver } from '@core/resolvers';
import { NavService, ResizeService } from '@core/services';

export const http: Provider[] = [
  AutoridadHttpService,
  ContactoHttpService,
  MenuHttpService,
  NoticiaHttpService,
  PaginaHttpService,
  ProgramaHttpService,
  RedSocialHttpService,
  RevistaHttpService,
  RutaHttpService,
  ServicioHttpService,
  SliderHttpService,
  TesisHttpService,
  TransparenciaHttpService,
];

export const services: Provider[] = [NavService, ResizeService];

export const interceptors = [
  {
    provide: HTTP_INTERCEPTORS,
    useClass: ApiInterceptor,
    multi: true,
  },
];

export const resolvers = [RouteResolver];
