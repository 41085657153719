import { Component, OnInit } from '@angular/core';
import { NoticiaHttpService } from '@core/http';
import { Noticia } from '@core/models';

@Component({
  selector: 'espg-tab-destacados',
  templateUrl: './tab-destacados.component.html',
  styleUrls: ['./tab-destacados.component.scss'],
})
export class TabDestacadosComponent implements OnInit {
  public noticias: Promise<Noticia[]>;

  constructor(private readonly noticiasHttpService: NoticiaHttpService) {
    this.noticias = this.noticiasHttpService.getNoticias(3);
  }

  ngOnInit(): void {}
}
