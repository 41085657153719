import { Component } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';

import SwiperCore, { Navigation, Autoplay } from 'swiper';

import { NoticiaHttpService, SliderHttpService } from '@core/http';
import { ElementoSlider, Noticia} from '@core/models';
import { FormControl, FormGroup } from '@angular/forms';


SwiperCore.use([Navigation, Autoplay]);

@Component({
  selector: 'espg-home',
  templateUrl: './home.page.html',
  styleUrls: ['./home.page.scss'],
})
export class HomePage {
  public contactForm!: FormGroup;
  public imagen:string = "";
  public noticias!: Noticia[];
  public elementosSlider!: ElementoSlider[];
  isVisible = false;
  isConfirmLoading = false;

  constructor(
    private readonly sliderHttpService: SliderHttpService,
    private readonly noticiasHttpService: NoticiaHttpService,
    private titleService: Title, private metaService: Meta,
  ) {
    this.titleService.setTitle("Escuela de PostGrado UPT");
    this.metaService.addTags([
      {name: 'description', content: "Obtén tu maestría o doctorado en nuestra escuela de postgrado. Programas de alta calidad, flexibles y con reconocimiento internacional. Abiertas las inscripciones para el proceso de admisión. ¡Avanza en tu carrera con un posgrado hoy!" },
      {name: 'keywords', content: "maestría, doctorado, escuela de postgrado, proceso de admisión, posgrado, educación superior, carrera profesional, maestria, maestrias, doctorados, admision, admisión, postgrado, upt, tacna, escuela, UPT, postgrado, postgrado upt" },
    ]);
    this.sliderHttpService.getImagenesSlider().then((result) => {
      this.elementosSlider = result;
      this.imagen = this.elementosSlider[0].imagen_movil
    });
   this.noticiasHttpService.getNoticiasDetacadas().then((result) => {
      this.noticias = result

      if (this.noticias.length){
        this.showModal();

      }
    });
  }

  selectValidator(control: FormControl): { [key: string]: boolean } | null {
    return control.value === 'S' ? { 'required': true } : null;
  }
  
  showModal(): void {
    this.isVisible = true;
  }


  handleOk(): void {
    this.isConfirmLoading = true;
    setTimeout(() => {
      this.isVisible = false;
      this.isConfirmLoading = false;
    }, 1000);
  }

  handleCancel(): void {
    this.isVisible = false;
  }
   


}
