import { Component } from '@angular/core';

import { RevistaHttpService } from '@core/http';
import { TipoRevista } from '@core/models';

@Component({
  selector: 'espg-lista-revistas-layout',
  templateUrl: './lista-revistas-layout.component.html',
  styleUrls: ['./lista-revistas-layout.component.scss'],
})
export class ListaRevistasLayoutComponent {
  public tiposDeRevista: Promise<TipoRevista[]>;

  constructor(private readonly revistaHttpService: RevistaHttpService) {
    this.tiposDeRevista =
      this.revistaHttpService.getTiposDeRevistas('revistas');
  }

  public onErrorImage(event: any) {
    event.target.src = 'assets/img/no-image.png';
    event.onerror = null;
  }
}
