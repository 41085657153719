import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { RedSocial } from '@core/models';

@Injectable()
export class RedSocialHttpService {
  constructor(private readonly httpClient: HttpClient) {}

  public getRedesSociales(): RedSocial[] {
    return [
      {
        url: 'https://www.facebook.com/PostgradoUPT/',
        icono: 'facebook.svg',
        nombre: 'Facebook',
      },
      {
        url: 'https://www.linkedin.com/in/escuela-de-postgrado-upt-7a7a9851',
        icono: 'linkedin.svg',
        nombre: 'LinkedIn',
      },
      {
        url: 'https://www.youtube.com/channel/UCrlhfnkw3RnvNJuL8tcIUnw',
        icono: 'youtube.svg',
        nombre: 'Youtube',
      },
      {
        url: 'https://twitter.com/PostGradoUPT/',
        icono: 'twitter.svg',
        nombre: 'Twitter',
      },
      {
        url: 'https://www.instagram.com/postgradoupt/',
        icono: 'instagram.svg',
        nombre: 'Instagram',
      },
    ];
  }
}
