export * from './autoridad.http';
export * from './contacto.http';
export * from './menu.http';
export * from './noticia.http';
export * from "./pagina.http";
export * from './programa.http';
export * from './red-social.http';
export * from './revista.http';
export * from './ruta.http';
export * from './servicio.http';
export * from './slider.http';
export * from './tesis.http';
export * from './transparencia.http';
