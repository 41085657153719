import { NgModule } from '@angular/core';

import { SharedModule } from '@shared/shared.module';
import { NoticiaLayoutComponent } from './noticia-layout.component';

@NgModule({
  imports: [SharedModule],
  exports: [NoticiaLayoutComponent],
  declarations: [NoticiaLayoutComponent],
})
export class NoticiaLayoutModule {}
