import { ErrorModule } from './error/error.module';
import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { ContentModule } from '@content/content.module';
import { CoreModule } from '@core/core.module';
import { HomeModule } from '@home/home.module';

import { AppComponent } from './app.component';
import { AppRoutes } from './app.routes';
import { registerLocaleData } from '@angular/common';
import localeEsPE from "@angular/common/locales/es-PE";
import { TagModule } from './tag/tag.module';

registerLocaleData(localeEsPE,'es-PE');

@NgModule({
  declarations: [AppComponent],
  imports: [
    AppRoutes,
    BrowserAnimationsModule,
    BrowserModule,
    ContentModule,
    CoreModule,
    ErrorModule,
    HomeModule,
    TagModule,
  ],
  // providers: [ { provide: LOCALE_ID, useValue: 'es-PE' } ],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule {}
