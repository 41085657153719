import { NgModule } from '@angular/core';

import { SharedModule } from '@shared/shared.module';
import { ListaProgramasLayoutComponent } from './lista-programas-layout.component';

@NgModule({
  imports: [SharedModule],
  exports: [ListaProgramasLayoutComponent],
  declarations: [ListaProgramasLayoutComponent],
})
export class ListaProgramasLayoutModule {}
