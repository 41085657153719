import { Subscription, tap } from 'rxjs';
import { Component, OnInit } from '@angular/core';
import { ElementoMenu } from '@core/models';
import { NavService } from '@core/services';

@Component({
  selector: 'espg-menu',
  template: '',
})
export class MenuComponent implements OnInit {
  public items: ElementoMenu[] | undefined;

  public itemsNavSubscription: Subscription | undefined;

  constructor(public navService: NavService) {}

  ngOnInit() {
    this.itemsNavSubscription = this.suscribeToItemsNav();
  }

  public setPath(url: string) {
    return `/${url}`;
  }

  private suscribeToItemsNav() {
    return this.navService.itemsNav$
      .pipe(
        tap((items) => {
          this.items = items.filter((item) => item.visible === true);
        })
      )
      .subscribe();
  }
}
