import { Component } from '@angular/core';
import { Menu } from '@core/constants';

import { RedSocialHttpService, MenuHttpService } from '@core/http';
import { RedSocial, ElementoMenu } from '@core/models';

@Component({
  selector: 'espg-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent {
  redesSociales: Promise<RedSocial[]>;

  public elementosMenu: Promise<ElementoMenu[]>;

  constructor(
    private readonly redSocialHttpService: RedSocialHttpService,
    private readonly menuHttpService: MenuHttpService
  ) {
    this.redesSociales = this.getRedesSociales();
    this.elementosMenu = this.getElementosMenu();
  }

  private async getRedesSociales() {
    return this.redSocialHttpService.getRedesSociales();
  }

  private async getElementosMenu() {
    return (await this.menuHttpService.getElementosByMenu(Menu.PRINCIPAL)).filter((item) => item.url);
  }
  
  public setPath(url: string) {
    return `/${url}`;
  }
}
