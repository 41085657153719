import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ElementoSlider, SuccessResponse } from '@core/models';
import { firstValueFrom } from 'rxjs';

@Injectable()
export class SliderHttpService {
  constructor(private httpClient: HttpClient) {}

  public async getImagenesSlider(): Promise<ElementoSlider[]> {
    let url = 'api/slider/elementos'
    const httpCall = this.httpClient.get<SuccessResponse<ElementoSlider[]>>(url);
    const response = await firstValueFrom(httpCall);

    return response.data;
  }
}
