<header class="header">
    <div class="header-row header__top-bar">
        <ul class="top-bar">
            <li class="top-bar__item"><a href="https://net.upt.edu.pe" class="top-bar__item__link" style="font-size: revert;">Intranet</a></li>
            <li class="top-bar__item"><a href="https://aulaespg.upt.edu.pe/login/index.php" class="top-bar__item__link" style="font-size: revert;">Aula Virtual</a></li>
            <li class="top-bar__item"><a href="https://net.upt.edu.pe/tdv/weblogin/login.php" class="top-bar__item__link" style="font-size: revert;">Mesa de Partes</a></li>         
            <li class="top-bar__item"><a href="https://net.upt.edu.pe/pasarela/weblogin/login.php" class="top-bar__item__link" style="font-size: revert;">Pasarela Pagos</a></li>      
        </ul>
    </div>

    <div class="header-row header__main">
        <div class="main" style="margin-left: 1.5rem;">
            <a [routerLink]="['/']" class="main__logo">
                <img src="assets/img/espg-logo/blue-logo.svg" alt="Escuela de Postgrado UPT" class="main__logo--espg" />
            </a>
            <button id="btn-menu" class="main__btn-menu" (click)="openMobileMenu()">
        <i nz-icon nzType="menu"></i>
      </button>
        </div>
    </div>

    <div class="header-row header__nav">
        <nav class="nav">
            <espg-desktop-menu></espg-desktop-menu>
        </nav>
    </div>
</header>
