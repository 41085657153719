<section>
  <div class="card-container">
    <nz-card
      class="card"
      [nzCover]="tplPhoto"
      *ngFor="let noticia of noticias | async"
    >
      <nz-card-meta [nzTitle]="noticia.titulo" [nzDescription]="tplDescription">
      </nz-card-meta>

      <ng-template #tplDescription>
        <a [routerLink]="[noticia.slug]">Ver mas</a>
      </ng-template>

      <ng-template #tplPhoto>
        <img [src]="noticia.imagen" [alt]="noticia.titulo" />
      </ng-template>
    </nz-card>
  </div>
</section>
