import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router'
import { TransparenciaHttpService } from '@core/http';
import { TipoDocumentoTransparencia } from '@core/models';

@Component({
  selector: 'espg-transparencia-layout',
  templateUrl: './transparencia-layout.component.html',
  styleUrls: ['./transparencia-layout.component.scss'],
})
export class TransparenciaLayoutComponent implements OnInit {
  public tiposDocumentoTransparencia: Promise<TipoDocumentoTransparencia[]>;

  constructor(
    private readonly transparenciaHttpService: TransparenciaHttpService,
    private router: Router
  ) {
    const tipo = this.router.url.replace('/','');
    this.tiposDocumentoTransparencia =
      this.transparenciaHttpService.getTiposDocumentoTransparencia([
        'documentos',
      ], tipo);
  }

  ngOnInit(): void {}
}
