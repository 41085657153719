import { Component, TemplateRef } from '@angular/core';

import { NzModalRef, NzModalService } from 'ng-zorro-antd';

import { TesisHttpService } from '@core/http';
import { Tesis } from '@core/models';
import * as moment from 'moment';

@Component({
  selector: 'espg-lista-tesis-layout',
  templateUrl: './lista-tesis-layout.component.html',
  styleUrls: ['./lista-tesis-layout.component.scss'],
})
export class ListaTesisLayoutComponent {
  public sustentacionesTesis: Promise<Tesis[]>;

  public tplModal?: NzModalRef;

  constructor(
    private readonly tesisHttpService: TesisHttpService,
    private readonly modalService: NzModalService
  ) {
    this.sustentacionesTesis = this.tesisHttpService.getTesis();
  }

  openModalDetails(
    tplContent: TemplateRef<{}>,
    tplFooter: TemplateRef<{}>,
    tesis: Tesis
  ) {
    this.tplModal = this.modalService.create({
      nzTitle: tesis.titulo,
      nzContent: tplContent,
      nzFooter: tplFooter,
      nzClosable: false,
      nzComponentParams: {
        tesis,
      },
    });
  }

  closeModalDetails() {
    this.tplModal?.close();
  }

  fncFormatFechaHora(date: any){
    return moment(date).utc().format("YYYY-MM-DDTHH:mm")
  }
}
