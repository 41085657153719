import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'espg-tab-academico',
  templateUrl: './tab-academico.component.html',
  styleUrls: ['./tab-academico.component.scss']
})
export class TabAcademicoComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
