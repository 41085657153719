import { Component, Input, ViewChild } from '@angular/core';

@Component({
  selector: 'espg-section',
  template: `<section class="section">
    <div
      [className]="
        mode === 'center' ? 'section__container' : 'section__container_full'
      "
    >
      <ng-content></ng-content>
    </div>
  </section>`,
  styleUrls: ['./section.component.scss'],
})
export class SectionComponent {
  @Input('mode')
  public mode: 'center' | 'full' | undefined;

  constructor() {
    if (this.mode === undefined) {
      this.mode = 'center';
    }
  }
}
