import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { firstValueFrom } from 'rxjs';

import { SuccessResponse, TipoDocumentoTransparencia } from '@core/models';

@Injectable()
export class TransparenciaHttpService {
  constructor(private httpClient: HttpClient) {}

  async getTiposDocumentoTransparencia(
    include: string[],
    tipo?: string,
  ): Promise<TipoDocumentoTransparencia[]> {
    let url = 'api/documentos-transparencia/tipos';
    let queryParams = new HttpParams();

    if (include) {
      queryParams = queryParams.set('include', include.join(','));
      url += `?${queryParams.toString()}`;
    }

    const httpCall =
      this.httpClient.get<SuccessResponse<TipoDocumentoTransparencia[]>>(url);
    const response = await firstValueFrom(httpCall);
   
    return response.data.filter(res => res.slug == tipo);
  }
}
