<div class="template--contact">
    <div class="contact__form">
        <h2>Solicita información</h2>

    <form nz-form [formGroup]="contactForm" (ngSubmit)="sendMessage()">
            <nz-form-item>
                <nz-form-label nzRequired>Programa de interés</nz-form-label>
                <nz-form-control [nzErrorTip]="errorPrograma"> 
                  <nz-select formControlName="programa" style="width: 100%;" nzSize="large" nzShowSearch nzPlaceHolder="Seleccione un programa">
                      <nz-option nzLabel="Seleccione un programa"  nzValue="S"></nz-option>
                    <div *ngFor="let programa of programas" >
                      <nz-option [nzLabel]="programa.nombre"  [nzValue]="programa.nombre"></nz-option>
                    </div>
                  </nz-select>
                </nz-form-control>
            </nz-form-item>


            <nz-form-item class="flex justify-between" >
              <nz-form-item class="w-6/12" >
                <nz-form-label nzRequired>Tipo Documento Identidad</nz-form-label>                
                <nz-form-control [nzErrorTip]="errorTipoDocumento"> 
                  <nz-select formControlName="tipodocumento" style="width: 100%;" nzSize="large" nzPlaceHolder="Seleccione">
                    <nz-option nzLabel="Seleccione"  nzValue="S"></nz-option>
                      <nz-option nzLabel="DNI"  nzValue="DNI"></nz-option>
                      <nz-option nzLabel="Carnet de extranjería"  nzValue="Carnet de extranjería"></nz-option>
                      <nz-option nzLabel="Pasaporte"  nzValue="Pasaporte"></nz-option>
                  </nz-select>                
                </nz-form-control>
              </nz-form-item>
              <nz-form-item  class="w-6/12 ml-4">
                <nz-form-label nzRequired [nzSpan]="24">
                    N° de Documento de Identidad
                </nz-form-label>
                <nz-form-control nzErrorTip="Por favor ingresa tu documento de identidad">
                    <input nz-input name="doc-identidad" type="text" id="txt-numero-documento-identidad" class="form-control" placeholder="DNI, Pasaporte, etc." formControlName="documento_identidad" required />
                </nz-form-control>
              </nz-form-item>
            </nz-form-item>
            
            <nz-form-item>
                <nz-form-label nzRequired>Nombres</nz-form-label>
                <nz-form-control nzErrorTip="Por favor ingresa tus nombres">
                    <input nz-input name="nombres" type="text" id="txt-nombres" class="form-control" formControlName="nombres" />
                </nz-form-control>
            </nz-form-item>

            <nz-form-item>
                <nz-form-label nzRequired>Apellidos</nz-form-label>
                <nz-form-control nzErrorTip="Por favor ingresa tus apellidos">
                    <input nz-input name="apellidos" type="text" id="txt-apellidos" class="form-control" formControlName="apellidos" />
                </nz-form-control>
            </nz-form-item>

            <nz-form-item>
                <nz-form-label nzRequired> Celular </nz-form-label>
                <nz-form-control nzErrorTip="Por favor ingresa tu teléfono o celular">
                    <input nz-input name="telefono" type="tel" id="txt-telefono" class="form-control" formControlName="telefono" />
                </nz-form-control>
            </nz-form-item>

            <nz-form-item>
                <nz-form-label nzRequired nzFor="txt-correo">
                    Correo electrónico
                </nz-form-label>
                <nz-form-control nzErrorTip="El correo electrónico no es válido">
                    <input nz-input name="txt-correo" type="email" id="txt-correo" class="form-control" formControlName="email" />
                </nz-form-control>
            </nz-form-item>

            <nz-form-item>
              <nz-form-label nzRequired>Departamento</nz-form-label>
              <nz-form-control [nzErrorTip]="errorDepartamento"> 
                <nz-select formControlName="departamento" style="width: 100%;" nzSize="large" nzShowSearch nzPlaceHolder="Seleccione un departamento">
                    <nz-option nzLabel="Seleccione un departamento"  nzValue="S"></nz-option>
                  <div *ngFor="let departamento of departamentosPeru" >
                    <nz-option [nzLabel]="departamento"  [nzValue]="departamento"></nz-option>
                  </div>
                </nz-select>
              </nz-form-control>
          </nz-form-item>

  
            <nz-form-item class="flex">
             <label nz-checkbox formControlName="aceptar_acuerdo" class="text-justify"></label>
             <p class="ml-1">He leído y acepto la  <a href="https://postgrado.upt.edu.pe/uploadsgc/politica_proteccion_datos.pdf"  class="text-espg-cobalt-blue font-bold" target="_blank">política de protección de datos personales UPT.</a></p>
            </nz-form-item>

      <nz-form-item>
        <nz-form-control>
          <button nz-button nzType="primary" nzSize="large">Enviar</button>
        </nz-form-control>
      </nz-form-item>
    </form>
  </div>

  <div class="contact__media">
    <nz-card class="card">
      <nz-card-meta
        nzTitle="Directorio Telefónico"
        [nzDescription]="tplDescription"
      >
      </nz-card-meta>

      <ng-template #tplDescription>
        <p>
          Ponemos a disposicion nuestro directorio telefónico para que puedas
          comunicarte con nosotros
        </p>
        <a routerLink="/directorio" nzType="link" nz-button>Ver directorio</a>
      </ng-template>
    </nz-card>

    <nz-card class="card mapa">
      <div class="location-map top-0">
        <google-map width="100%" [center]="locationCoordinates" [zoom]="18" class="top-0">
          <map-marker  #marker="mapMarker" [position]="locationCoordinates" (mapClick)="openInfoWindow(marker)"></map-marker>
          <map-info-window> Escuela de PostGrado UPT
      
          </map-info-window>
        </google-map>
      </div>
    </nz-card>

  </div>
</div>

<ng-template #errorPrograma let-control>
  <ng-container *ngIf="control.hasError('required')">
    Por favor seleccione un programa
  </ng-container>
</ng-template>

<ng-template #errorDepartamento let-control>
  <ng-container *ngIf="control.hasError('required')">
    Por favor seleccione un departamento
  </ng-container>
</ng-template>





<ng-template #errorTipoDocumento let-control>
  <ng-container *ngIf="control.hasError('required')">
    Por favor seleccione un tipo de documento
  </ng-container>
</ng-template>