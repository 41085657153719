<footer class="footer">
  <div class="footer-row footer__main">
    <div class="main">
      <div class="main__item contact-info">
        <img
          src="assets/img/espg-logo/blue-logo.svg"
          alt="UPT - Escuela de Postgrado"
          class="contact-info__logo--espg"
        />
        <p class="contact-info__text">Av. Bolognesi N° 1916 - Tacna, Perú</p>
        <p class="contact-info__text">Telef: +51 (052) 427212</p>
      </div>
      <div class="main__item nav">
        <div class="nav--list">
          <h3 class="nav--list__heading">Enlaces Principales</h3>          
          <div class="nav--list" >
            <ul class="nav--list__body">
              <li *ngFor="let elemento of elementosMenu | async" class="nav--list__body__item"> 
                <a  *ngIf="elemento.visible" [href]="elemento.url">{{elemento.titulo}}</a>      
              </li>
              <li> 
                <a routerLink="/directorio">Directorio telefónico</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="footer-row footer__copyright">
    <div class="copyright">
      <small class="copyright__text"
        >Escuela de Postgrado de la Universidad Privada de Tacna © 2021</small
      >
    </div>
  </div>
</footer>
