import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import {
  Component,
  OnInit,
  OnDestroy,
  HostListener,
  AfterViewInit,
} from '@angular/core';

import { Subscription } from 'rxjs';
import { distinctUntilChanged, tap } from 'rxjs/operators';

import { ScreenSize } from '@core/constants';
import { MenuHttpService } from '@core/http';
import { NavService, ResizeService } from '@core/services';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';

declare var gtag:any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [
    trigger('mobileMenuState', [
      state(
        'show',
        style({
          width: '100%',
        })
      ),
      state(
        'hide',
        style({
          width: '0',
        })
      ),
      transition('hide => show', animate(200)),
      transition('show => hide', animate(200)),
    ]),
  ],
})
export class AppComponent implements OnInit, OnDestroy, AfterViewInit {
  public mobileMenuState: 'show' | 'hide';

  private showMobileMenuSubscription: Subscription | undefined;

  private resizeSubscription: Subscription | undefined;

  constructor(
    private readonly menuHttpService: MenuHttpService,
    private readonly navService: NavService,
    private readonly resizeService: ResizeService, 
    private router: Router
  ) {
    this.getItemsMenu();
    this.mobileMenuState = 'hide';

    
    const navEndEvents$ = this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    );
    navEndEvents$.subscribe((event: any)=> {
      gtag('config', 'UA-229147608-1', {
        'page_path': event.urlAfterRedirects
      });
    })
  }

  public ngOnInit() {
    this.showMobileMenuSubscription = this.subscribeToShowMobileMenu$();
    this.resizeSubscription = this.subscribeToResize$();
  }

  public ngAfterViewInit() {
    this.onResize();
  }

  public ngOnDestroy() {
    this.showMobileMenuSubscription?.unsubscribe();
    this.resizeSubscription?.unsubscribe();
  }

  private getItemsMenu(): void {
    this.menuHttpService.getMapaSitio().then((items) => {
      this.navService.triggerItemsNav$(items);
    });
  }

  private subscribeToShowMobileMenu$(): Subscription {
    return this.navService.showMobileMenu$
      .pipe(tap((value) => (this.mobileMenuState = value ? 'show' : 'hide')))
      .subscribe();
  }

  private subscribeToResize$() {
    return this.resizeService.resize$
      .pipe(
        distinctUntilChanged(),
        tap((size) => {
          if (size >= ScreenSize.LG && this.mobileMenuState === 'show') {
            this.navService.triggerShowMobileMenu$(false);
          }
        })
      )
      .subscribe();
  }

  @HostListener('window:resize', [])
  public onResize(): void {
    this.resizeService.triggerResize();
  }
}
