import { NgModule } from '@angular/core';
import { SharedModule } from '@shared/shared.module';
import { TagComponent } from './tag.component';



@NgModule({
  imports: [SharedModule],
  exports: [TagComponent],
  declarations: [TagComponent],
})
export class TagModule { }
