import {
  Compiler,
  ComponentRef,
  Injectable,
  ViewContainerRef,
} from '@angular/core';

import { from, map, Observable } from 'rxjs';

import { ContentLayout } from '@core/constants';

@Injectable()
export class ContentLayoutService {
  constructor(private compiler: Compiler) {}

  public loadLayout(
    vcr: ViewContainerRef,
    layout: ContentLayout
  ): Observable<ComponentRef<any>> {
    vcr.clear();

    const layouts = this.getLayouts();
    const selectedLayout = layouts[layout - 1];
    const layoutToLoad = () => selectedLayout;

    return from(layoutToLoad()).pipe(
      map((module: any) =>
        this.compiler.compileModuleAndAllComponentsSync(module)
      ),
      map((components) => components.componentFactories[0]),
      map((componentFactory) => vcr.createComponent(componentFactory))
    );
  }

  private getLayouts() {
    return [
      import('../components').then((m) => m.GenericoLayoutModule),
      import('../components').then((m) => m.AutoridadesLayoutModule),
      import('../components').then((m) => m.TransparenciaLayoutModule),
      import('../components').then((m) => m.ListaProgramasLayoutModule),
      import('../components').then((m) => m.ProgramaLayoutModule),
      import('../components').then((m) => m.ListaServiciosLayoutModule),
      import('../components').then((m) => m.ListaNoticiasLayoutModule),
      import('../components').then((m) => m.NoticiaLayoutModule),
      import('../components').then((m) => m.ListaRevistasLayoutModule),
      import('../components').then((m) => m.ContactoLayoutModule),
      import('../components').then((m) => m.ListaTesisLayoutModule),
      import('../components').then((m) => m.ProgramasConvocadosLayoutModule),
    ];
  }
}
