import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { pluck, tap } from 'rxjs';

@Component({
  selector: 'espg-generico-layout',
  template: '<article [innerHTML]="htmlCode"></article>',
  styleUrls: ['./generico-layout.component.scss'],
})
export class GenericoLayoutComponent implements OnInit {
  public htmlCode: string | undefined;

  constructor(private route: ActivatedRoute) {}

  ngOnInit(): void {
    this.getContentHtml();
  }

  private getContentHtml() {
    this.route.data
      .pipe(
        pluck('pageData'),
        tap((response) => (this.htmlCode = response.contenido_html))
      )
      .subscribe();
  }
}
