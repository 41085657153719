import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { ProgramaHttpService } from '@core/http';
import { Programa } from '@core/models';
import { getSlugSegment } from '@shared/util';

@Component({
  selector: 'espg-lista-programas-layout',
  templateUrl: './lista-programas-layout.component.html',
  styleUrls: ['./lista-programas-layout.component.scss'],
})
export class ListaProgramasLayoutComponent implements OnInit {
  public programas: Programa[] | undefined;

  constructor(
    private readonly programaHttpService: ProgramaHttpService,
    private readonly route: Router
  ) {}

  ngOnInit(): void {
    this.getProgramas();
  }

  private getProgramas() {
    const slug = getSlugSegment(this.route.url);

    this.programaHttpService
      .getAllProgramas(null, true, slug)
      .then((data) => {
        this.programas = data.map( (element) =>  {
          element.imagen = element.imagen?.length > 10 ? element.imagen : ''; 
          return element
        })
      });
  }
}
