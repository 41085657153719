import { BehaviorSubject, Observable } from 'rxjs';
import { Injectable } from '@angular/core';

import { ElementoMenu } from '@core/models';

@Injectable()
export class NavService {
  private itemsNavSubject: BehaviorSubject<ElementoMenu[]>;

  private showMobileMenuSubject: BehaviorSubject<boolean>;

  constructor() {
    this.itemsNavSubject = new BehaviorSubject<ElementoMenu[]>([]);
    this.showMobileMenuSubject = new BehaviorSubject<boolean>(false);
  }

  get itemsNav$(): Observable<ElementoMenu[]> {
    return this.itemsNavSubject.asObservable();
  }

  triggerItemsNav$(items: ElementoMenu[]): void {
    this.itemsNavSubject.next(items);
  }

  get showMobileMenu$() {
    return this.showMobileMenuSubject.asObservable();
  }

  triggerShowMobileMenu$(status: boolean) {
    this.showMobileMenuSubject.next(status);
  }
}
