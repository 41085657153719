import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { firstValueFrom } from 'rxjs';

import { Menu } from '@core/constants';
import { ElementoMenu, SuccessResponse } from '@core/models';

@Injectable()
export class MenuHttpService {
  constructor(private httpClient: HttpClient) {}

  async getMapaSitio(): Promise<ElementoMenu[]> {
    const url = 'api/mapa-sitio';
    const httpCall = this.httpClient.get<SuccessResponse<ElementoMenu[]>>(url);
    const response = await firstValueFrom(httpCall);

    return response.data;
  }

  async getElementosByMenu(id: Menu, etiqueta?: string): Promise<ElementoMenu[]> {
    let url = `api/menus/${id}/elementos`;
    let complemento = etiqueta? '?etiqueta=' + etiqueta : '';
    url = url + complemento;
    const httpCall = this.httpClient.get<SuccessResponse<ElementoMenu[]>>(url);
    const response = await firstValueFrom(httpCall);

    return response.data;
  }


  async getElementosByEtiqueta(etiqueta: string): Promise<ElementoMenu[]> {
    let url = `api/etiquetas/${etiqueta}/enlaces`;
    const httpCall = this.httpClient.get<SuccessResponse<ElementoMenu[]>>(url);
    const response = await firstValueFrom(httpCall);

    return response.data;
  }
}
