<button id="btn-close-mobile-menu" (click)="close()">
  <i nz-icon nzType="close"></i>
</button>

<ul class="mobile-menu__container" nz-menu nzMode="inline">
    <ng-container *ngTemplateOutlet="tplMenu; context: { $implicit: items }"></ng-container>

    <ng-template #tplMenu let-items>
        <ng-container *ngFor="let item of items">
            <li nz-submenu *ngIf="item.tipo === 'Encabezado' && item.nivel === 1" [nzTitle]="item.titulo">
                <ul>
                    <ng-container *ngTemplateOutlet="
              tplMenu;
              context: { $implicit: item.subelementos }
            "></ng-container>
                </ul>
            </li>

            <li nz-menu-group *ngIf="item.tipo === 'Encabezado' && item.nivel > 1" [nzTitle]="item.titulo">
                <ul>
                    <ng-container *ngTemplateOutlet="
              tplMenu;
              context: { $implicit: item.subelementos }
            "></ng-container>
                </ul>
            </li>

            <li nz-menu-item *ngIf="item.tipo !== 'Encabezado'">
                <a *ngIf="item.tipo === 'Enlace'" [href]="item.redireccion" (click)="close()">{{
          item.titulo
        }}</a>
                <a *ngIf="item.tipo === 'Pagina'" routerLinkActive="activo" [routerLink]="setPath(item.redireccion)" (click)="close()">
          {{ item.titulo }}
        </a>
            </li>
        </ng-container>
        <li nz-menu-item> <a href="https://net.upt.edu.pe" (click)="close()">Intranet</a></li>
        <li nz-menu-item> <a href="https://aulaespg.upt.edu.pe/login/index.php" (click)="close()">Aula Virtual</a></li>
    </ng-template>
</ul>