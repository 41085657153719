import { Component, OnInit } from '@angular/core';
import { ServicioHttpService } from '@core/http';
import { Servicio } from '@core/models';

@Component({
  selector: 'espg-tab-servicios',
  templateUrl: './tab-servicios.component.html',
  styleUrls: ['./tab-servicios.component.scss'],
})
export class TabServiciosComponent implements OnInit {
  public servicios: Promise<Servicio[]>;

  constructor(private readonly serviciosHttpService: ServicioHttpService) {
    this.servicios = this.serviciosHttpService.getServicios(4);
  }

  ngOnInit(): void {}
}
