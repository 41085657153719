<section>
  <div class="card-container">
    <nz-card class="card" *ngFor="let servicio of servicios | async">
      <nz-card-meta
        [nzTitle]="servicio.nombre"
        [nzDescription]="tplDescription"
      >
      </nz-card-meta>

      <ng-template #tplDescription>
        <p>{{ servicio.descripcion }}</p>

        <a [href]="servicio.url" target="_blank" class="link">Acceder</a>
      </ng-template>
    </nz-card>
  </div>
</section>
