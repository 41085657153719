import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { firstValueFrom } from 'rxjs';

import { SuccessResponse, Tesis } from '@core/models';

@Injectable()
export class TesisHttpService {
  constructor(private httpClient: HttpClient) {}

  async getTesis(): Promise<Tesis[]> {
    const url = 'api/tesis';
    const httpCall = this.httpClient.get<SuccessResponse<Tesis[]>>(url);
    const response = await firstValueFrom(httpCall);

    return response.data;
  }
}
