import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';

import { Observable } from 'rxjs';

@Injectable()
export class ApiInterceptor implements HttpInterceptor {
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    let baseUrl = 'https://postgrado.upt.edu.pe/api/';

    const url = req.url.includes("http") ? req.url : baseUrl + req.url;
    
    const apiRequest = req.clone({ url });

    return next.handle(apiRequest);
  }
}
