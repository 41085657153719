<ul class="sidenav">
  <ng-container
    *ngTemplateOutlet="tplMenu; context: { $implicit: items }"
  ></ng-container>

  <ng-template #tplMenu let-items>
    <ng-container *ngFor="let item of items">
      <li
        class="sidenav__item sidenav__group"
        *ngIf="item.tipo === 'Encabezado' && item.visible"
      >
        <span class="sidenav__group--title">{{ item.titulo }}</span>
        <ul class="sidenav__submenu">
          <ng-container
            *ngTemplateOutlet="
              tplMenu;
              context: { $implicit: item.subelementos }
            "
          ></ng-container>
        </ul>
      </li>

      <li class="sidenav__item" *ngIf="item.tipo !== 'Encabezado'">
        <a
          class="sidenav__item__link"
          *ngIf="item.tipo === 'Enlace'"
          [href]="item.redireccion"
          target="_blank"
          >{{ item.titulo }}</a
        >
        <a
          class="sidenav__item__link"
          *ngIf="item.tipo === 'Pagina'"
          routerLinkActive="sidenav__item__link--active"
          [routerLink]="setLink(item.redireccion)"
        >
          {{ item.titulo }}
        </a>
      </li>
    </ng-container>
  </ng-template>
</ul>
