import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { ProgramaHttpService } from '@core/http';
import { Programa } from '@core/models';
import { getSlugSegment } from '@shared/util';

@Component({
  selector: 'espg-programa-layout',
  templateUrl: './programa-layout.component.html',
  styleUrls: ['./programa-layout.component.scss'],
})
export class ProgramaLayoutComponent implements OnInit {
  public programa: Programa | undefined;

  public hasDocentes: boolean;

  constructor(
    private readonly programaHttpService: ProgramaHttpService,
    private readonly route: Router
  ) {
    this.hasDocentes = false;
  }

  ngOnInit(): void {
    this.getPrograma();
  }

  private getPrograma() {
    const slug = getSlugSegment(this.route.url);
    this.programaHttpService.getPrograma(slug).then((response) => {
      this.programa = response;
      this.hasDocentes = this.programa.docentes.length > 0;
    });
  }
}
