import { NgModule } from '@angular/core';

import { SharedModule } from '@shared/shared.module';
import { ListaServiciosLayoutComponent } from './lista-servicios-layout.component';

@NgModule({
  imports: [SharedModule],
  exports: [ListaServiciosLayoutComponent],
  declarations: [ListaServiciosLayoutComponent],
})
export class ListaServiciosLayoutModule {}
