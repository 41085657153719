import { Component } from '@angular/core';

import { MenuComponent } from '@core/components';
import { NavService } from '@core/services';

@Component({
  selector: 'espg-desktop-menu',
  templateUrl: './desktop-menu.component.html',
  styleUrls: ['./desktop-menu.component.scss'],
})
export class DesktopMenuComponent extends MenuComponent {
  constructor(public override navService: NavService) {
    super(navService);
  }

  openSubmenu(e: MouseEvent) {
    const currentElement = e.target as HTMLElement;
    const submenu = currentElement.querySelector('.menu__submenu');

    if (submenu) {
      submenu.classList.add('menu__submenu--open');
    }
  }

  closeSubmenu(e: MouseEvent) {
    const submenuShown = document.querySelector(
      '.desktop-menu .menu__submenu--open'
    );

    if (submenuShown) {
      submenuShown.classList.remove('menu__submenu--open');
    }
  }
}
