import { Component, OnInit } from '@angular/core';
import { ElementoMenu } from '../core/models/elemento-menu.model';
import { MenuHttpService } from '../core/http/menu.http';
import { Router, ActivatedRoute } from '@angular/router';
import { getSlugSegment } from '@shared/util';

@Component({
  selector: 'espg-tag',
  templateUrl: './tag.component.html',
  styleUrls: ['./tag.component.scss']
})
export class TagComponent implements OnInit {
  public etiqueta: string;
  public enlaces: ElementoMenu[];

  constructor(private route: Router, private activatedRoute: ActivatedRoute, private readonly menuHttpService: MenuHttpService,) {
    this.etiqueta =  getSlugSegment(this.route.url);
    this.enlaces = [];
   }

  ngOnInit(): void {
    this.activatedRoute.url.subscribe((resp) => {
        this.enlaces = [];
        this.etiqueta = resp.pop()?.path || "";
        this.getItemsMenu();
    });
  }

  private getItemsMenu(): void {
    this.menuHttpService.getElementosByEtiqueta(this.etiqueta).then((items) => {
        this.enlaces = items;
    });
  }

  public setPath(url: string) {
    return `/${url}`;
  }


}
