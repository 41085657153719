<nz-tabset>
    <nz-tab [nzTitle]="tipo.nombre" *ngFor="let tipo of tiposDocumentoTransparencia | async">
        <nz-table #tabla [nzData]="tipo.documentos">
            <thead>
                <tr>
                    <th>Documento</th>
                    <!-- <th>Fecha</th> -->
                    <th>Archivo</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let documentos of tabla.data">
                    <td>{{ documentos.nombre }}</td>
                    <!-- <td>{{ documentos.fecha | date:'MMMM-yyyy' }}</td> -->
                    <td>
                        <a [href]="documentos.archivo" class="button" target="_blank">Descargar</a
            >
          </td>
        </tr>
      </tbody>
    </nz-table>
  </nz-tab>
</nz-tabset>

