import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router } from '@angular/router';

import { EMPTY } from 'rxjs';

import { RutaHttpService } from '@core/http';

@Injectable()
export class RouteResolver implements Resolve<any> {
  constructor(
    private readonly router: Router,
    private readonly rutaHttpService: RutaHttpService
  ) {}

  async resolve(route: ActivatedRouteSnapshot) {
    const url = route.url.join('/');
    const transformedUrl = url.replace('/', ':');
    const queryInclude = ['pagina', 'pagina.plantilla'];

    try {
      const response = await this.rutaHttpService.getRuta(
        transformedUrl,
        queryInclude
      );
      return response.data.pagina;
    } catch (e) {
      this.router.navigate(['404']);
      return EMPTY;
    }
  }
}
