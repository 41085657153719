<div class="tab-content--destacados">
  <div class="tab-content__body card-container">

    <nz-card class="card card-start">
      <nz-card-meta
        nzTitle="Maestrías"
        [nzDescription]="tplDescription"
      >
      </nz-card-meta>
  
      <ng-template #tplDescription>
        <p>Conozca nuestra oferta académica de maestrías</p>
        <a nz-button nzType="link" [routerLink]="['maestrias']"
          >Ver más</a
        >
      </ng-template>
    </nz-card>

    
  <nz-card class="card">
    <nz-card-meta
      nzTitle="Doctorados"
      [nzDescription]="tplDescription"
    >
    </nz-card-meta>

    <ng-template #tplDescription>
      <p>Conozca nuestra oferta académica de doctorados</p>
      <a nz-button nzType="link" [routerLink]="['doctorados']"
        >Ver más</a
      >
    </ng-template>
  </nz-card>

  </div>
</div>
