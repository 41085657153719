import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { firstValueFrom } from 'rxjs';

import { Ruta, SuccessResponse } from '@core/models';

@Injectable()
export class RutaHttpService {
  constructor(private httpClient: HttpClient) {}

  async getRuta(
    uri: string,
    include: string[]
  ): Promise<SuccessResponse<Ruta>> {
    let url = `api/rutas/${uri}`;
    let queryParams = new HttpParams();

    if (include) {
      queryParams = queryParams.set('include', include.join(','));
      url += `?${queryParams.toString()}`;
    }

    const httpCall = this.httpClient.get<SuccessResponse<Ruta>>(url);

    return await firstValueFrom(httpCall);
  }
}
