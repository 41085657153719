import { NgModule } from '@angular/core';
import { SharedModule } from '@shared/shared.module';
import { ProgramasConvocadosLayoutComponent } from './programas-convocados-layout.component';

@NgModule({
  imports: [SharedModule],
  exports: [ProgramasConvocadosLayoutComponent],
  declarations: [ProgramasConvocadosLayoutComponent],
})
export class ProgramasConvocadosLayoutModule { }
