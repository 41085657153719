<div class="tab-content--servicios">
  <div class="tab-content__body card-container">
    <nz-card class="card" *ngFor="let servicio of servicios | async">
      <nz-card-meta
        [nzTitle]="servicio.nombre"
        [nzDescription]="tplDescription"
      >
      </nz-card-meta>

      <ng-template #tplDescription>
        <p>{{ servicio.descripcion }}</p>

        <a [href]="servicio.url" target="_blank" nz-button nzType="link"
          >Acceder</a
        >
      </ng-template>
    </nz-card>
  </div>

  <div class="tab-content__actions">
    <div class="actions__button">
      <a
        [routerLink]="['servicios']"
        class="actions__button--see-more"
        nzType="primary"
        nz-button
        >Ver más</a
      >
    </div>
  </div>
</div>
