import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { firstValueFrom } from 'rxjs';

import { Programa, SuccessResponse, TipoPrograma } from '@core/models';

@Injectable()
export class ProgramaHttpService {
  constructor(private httpClient: HttpClient) {}

  async getProcesoAdmisionActivo(){
    const httpCall = this.httpClient.get("https://postgrado.upt.edu.pe/evaluacion/api/procesoadmisionactivo");
    const response = await firstValueFrom(httpCall);
    return response;
  }

  async getAllProgramas(
    limit: number | null,
    disponible: boolean | null,
    tipoPrograma: string | null,
    ...include: string[]
  ): Promise<Programa[]> {
    let queryParams = new HttpParams();

    if (include.length > 0) {
      queryParams = queryParams.set('include', include.join(','));
    }

    if (limit) {
      queryParams = queryParams.set('limit', limit);
    }

    if (tipoPrograma) {
      queryParams = queryParams.set('tipo', tipoPrograma);
    }

    if (disponible) {
      queryParams = queryParams.set('disponible', disponible ? '1' : '0');
    }

    let url = 'api/programas';
    if (queryParams.getAll.length > 0) {
      url += `?${queryParams.toString()}`;
    }
    const httpCall = this.httpClient.get<SuccessResponse<Programa[]>>(url);
    const response = await firstValueFrom(httpCall);

    return response.data;
  }

  async getPrograma(slug: string): Promise<Programa> {
    const url = `api/programa/${slug}`;
    const httpCall = this.httpClient.get<SuccessResponse<Programa>>(url);
    const response = await firstValueFrom(httpCall);

    return response.data;
  }

  async getTipoPrograma(
    slug: string,
    ...include: string[]
  ): Promise<TipoPrograma> {
    let queryParams = new HttpParams();

    if (include) {
      queryParams = queryParams.set('include', include.join(','));
    }

    let url = `api/programas/tipos/${slug}`;
    if (queryParams.getAll.length > 0) {
      url += `?${queryParams.toString()}`;
    }
    const httpCall = this.httpClient.get<SuccessResponse<TipoPrograma>>(url);
    const response = await firstValueFrom(httpCall);

    return response.data;
  }
}
