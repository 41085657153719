import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { LayoutDirective } from '@content/directives';
import { ContentLayoutService } from '@content/services';
import { Pagina } from '@core/models';
import { mergeMap, pluck } from 'rxjs';

@Component({
  selector: 'espg-content-layout',
  template: `<ng-template layout></ng-template>`,
})
export class LayoutComponent implements OnInit {
  @ViewChild(LayoutDirective, { static: true })
  public layoutDirective: LayoutDirective | undefined;

  constructor(
    private readonly route: ActivatedRoute,
    private readonly layoutService: ContentLayoutService
  ) {}

  ngOnInit(): void {
    this.subscribeToRouteData();
  }

  private subscribeToRouteData() {
    if (!this.layoutDirective) {
      return;
    }

    const viewContainerRef = this.layoutDirective.viewContainerRef;
    this.route.data
      .pipe(
        pluck('pageData'),
        mergeMap((pagina: Pagina) =>
          this.layoutService.loadLayout(viewContainerRef, pagina.plantilla.id)
        )
      )
      .subscribe();
  }
}
