<div class="tab-content--destacados">
  <div class="tab-content__body card-container">
    <nz-card
      class="card"
      [nzCover]="tplPhoto"
      *ngFor="let noticia of noticias | async"
    >
      <nz-card-meta [nzTitle]="noticia.titulo" [nzDescription]="tplDescription">
      </nz-card-meta>

      <ng-template #tplDescription>
        <a nz-button nzType="link" [routerLink]="['noticias/',noticia.slug]">Mas detalles</a>
      </ng-template>

      <ng-template #tplPhoto>
        <img [src]="noticia.imagen" [alt]="noticia.titulo" />
      </ng-template>
    </nz-card>
  </div>

  <div class="tab-content__actions">
    <div class="actions__button">
      <a
        [routerLink]="['noticias']"
        class="actions__button--see-more"
        nzType="primary"
        nz-button
        >Ver más</a
      >
    </div>
  </div>
</div>
