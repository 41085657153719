<div class="card-container">

  <nz-card class="card">
  <!-- <nz-card class="card" [nzCover]="tplPhoto"> -->
    <nz-card-meta
      nzTitle="Calendario académico"
      [nzDescription]="tplDescription"
    >
    </nz-card-meta>

    <ng-template #tplDescription>
      <p>Conozca el calendario académico para el presente semestre académico</p>
      <a nz-button nzType="link" [routerLink]="['calendario-academico']">Ver mas</a>
    </ng-template>

    <!-- <ng-template #tplPhoto>
      <img src="assets/img/academico/procedimiento-grado.jpg" />
    </ng-template> -->
  </nz-card>


  <nz-card class="card">
  <!-- <nz-card class="card" [nzCover]="tplPhoto"> -->
    <nz-card-meta
      nzTitle="Asignaturas no presenciales"
      [nzDescription]="tplDescription"
    >
    </nz-card-meta>

    <ng-template #tplDescription>
      <p>
        Conozca sobre las asignaturas a dictarse de manera no presencial durante
        la emergenca sanitaria
      </p>
      <a nz-button nzType="link" [routerLink]="['asignaturas-no-presenciales']">Ver mas</a>
    </ng-template>

    <!-- <ng-template #tplPhoto>
      <img src="assets/img/academico/calendario-academico.jpg" />
    </ng-template> -->
  </nz-card>

  <nz-card class="card">
  <!-- <nz-card class="card" [nzCover]="tplPhoto"> -->
    <nz-card-meta
      nzTitle="Trámites Documentarios"
      [nzDescription]="tplDescription"
    >
    </nz-card-meta>

    <ng-template #tplDescription>
      <p>
        Conozca sobre los requisitos y procedimientos para los distintos
        trámites a realizar
      </p>
      <a nz-button nzType="link" [routerLink]="['tramites-documentarios']">Ver mas</a>
    </ng-template>

    <!-- <ng-template #tplPhoto>
      <img src="assets/img/academico/matricula-web.jpg" />
    </ng-template> -->
  </nz-card>


  <nz-card class="card">
  <!-- <nz-card class="card" [nzCover]="tplPhoto"> -->
    <nz-card-meta nzTitle="Descuentos" [nzDescription]="tplDescription">
    </nz-card-meta>

    <ng-template #tplDescription>
      <p>Conozca sobre los beneficios económicos que ofrece la ESPG</p>
      <a nz-button nzType="link" [routerLink]="['descuentos']">Ver mas</a>
    </ng-template>
<!-- 
    <ng-template #tplPhoto>
      <img src="assets/img/academico/matricula-web.jpg" />
    </ng-template> -->
  </nz-card>
</div>
