<section *ngFor="let tipoAutoridad of tiposDeAutoridades | async">
  <h2>{{ tipoAutoridad.descripcion }}</h2>

  <div class="card-container">
    <nz-card
      class="card"
      *ngFor="let autoridad of tipoAutoridad.autoridades"
      [nzCover]="tplPhoto"
    >
      <nz-card-meta
        [nzTitle]="
          setCardTitle(
            autoridad.abreviatura_grado_academico,
            autoridad.nombres_completos
          )
        "
        [nzDescription]="tplDescription"
      >
      </nz-card-meta>

      <ng-template #tplDescription>
        <p>{{ autoridad.cargo }}</p>
      </ng-template>

      <ng-template #tplPhoto>
        <img
          [src]="autoridad.foto"
          [alt]="autoridad.nombres_completos"
          onerror="setSourceImageOnError(e, 'assets/img/no-avatar.png')"
        />
      </ng-template>
    </nz-card>
  </div>
</section>
