import { Component } from '@angular/core';

import { ServicioHttpService } from '@core/http';
import { Servicio } from '@core/models';

@Component({
  selector: 'espg-lista-servicios-layout',
  templateUrl: './lista-servicios-layout.component.html',
  styleUrls: ['./lista-servicios-layout.component.scss'],
})
export class ListaServiciosLayoutComponent {
  public servicios: Promise<Servicio[]>;

  constructor(private readonly servicioHttpService: ServicioHttpService) {
    this.servicios = this.servicioHttpService.getServicios();
  }
}
