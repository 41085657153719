<div class="card-container">
  <nz-card class="card" *ngFor="let tesis of sustentacionesTesis | async">
    <nz-card-meta [nzTitle]="tesis.titulo" [nzDescription]="tplDescription">
    </nz-card-meta>

    <ng-template #tplDescription>
      <table>
        <tr>
          <td>
            <dl>
              <dt>{{ tesis.tesistas.length > 1 ? "Tesistas" : "Tesista" }}</dt>
              <dd>
                <ul>
                  <li *ngFor="let tesista of tesis.tesistas">{{ tesista }}</li>
                </ul>
              </dd>
            </dl>
            <dl>
              <dt>Asesor</dt>
              <dd *ngIf="tesis.asesor.length == 0">No registrado</dd>
              <dd *ngIf="tesis.asesor.length != 0">{{tesis.asesor}}</dd>
            </dl>
            <dl>
              <dt>Programa</dt>      
              <dd>{{tesis.programa.nombre}}</dd>
            </dl>
          </td>
          <td style="padding-left: 1rem;">
            <dl>
              <dt>Jurado Examinador</dt>      
              <dt>Presidente</dt>      
              <dd>{{tesis.jurado.presidente}}</dd>
            </dl>
            <dl>
              <dt>Secretario</dt>      
              <dd>{{tesis.jurado.secretario}}</dd>
            </dl>
            <dl>
              <dt>Vocal</dt>      
              <dd>{{tesis.jurado.vocal}}</dd>
            </dl>
          </td>
        </tr>
      </table>


      <button
        nz-button
        nzType="primary"
        nzSize="large"
        (click)="openModalDetails(tplModalContemt, tplModalFooter, tesis)"
      >
        Ver detalles
      </button>
    </ng-template>
  </nz-card>
</div>

<ng-template #tplModalContemt let-params>
  <dl>
    <dt>{{ params.tesis.tesistas.length > 1 ? "Tesistas" : "Tesista" }}</dt>
    <dd>
      <ul>
        <li *ngFor="let tesista of params.tesis.tesistas">{{ tesista }}</li>
      </ul>
    </dd>
  </dl>

  <dl>
    <dt>Asesor</dt>
    <dd *ngIf="params.tesis.asesor.length == 0">No registrado</dd>
    <dd *ngIf="params.tesis.asesor.length != 0">{{params.tesis.asesor}}</dd>
  </dl>
  <dl>
    <dt>Lugar</dt>
    <dd> <a href="{{params.tesis.lugar}}" target="_blank">clic aquí</a></dd>
  </dl>

  <dl>
    <dt>Hora</dt>
    <dd>{{ fncFormatFechaHora(params.tesis.fecha_hora) | date: "dd MMMM yyyy HH:mm"}}</dd>
  </dl>

  <dl>
    <dt>Miembros del Jurado</dt>
    <dd>
      <ul>
        <li>
          <strong>Presidente:</strong> {{ params.tesis.jurado.presidente }}
        </li>
        <li>
          <strong>Secretario:</strong> {{ params.tesis.jurado.secretario }}
        </li>
        <li><strong>Vocal:</strong> {{ params.tesis.jurado.vocal }}</li>
      </ul>
    </dd>
  </dl>
</ng-template>

<ng-template #tplModalFooter>
  <button
    nz-button
    nzType="primary"
    nzSize="large"
    (click)="closeModalDetails()"
  >
    Cerrar
  </button>
</ng-template>
