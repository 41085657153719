import { ContentLayoutService } from '@content/services';
import { NgModule } from '@angular/core';

import { LayoutComponent, SidenavComponent } from '@content/components';
import { ContentPage } from '@content/content.page';
import { LayoutDirective } from '@content/directives';
import { SharedModule } from '@shared/shared.module';

@NgModule({
  declarations: [
    ContentPage,
    LayoutComponent,
    LayoutDirective,
    SidenavComponent,
  ],
  imports: [SharedModule],
  providers: [ContentLayoutService],
  exports: [ContentPage, LayoutComponent, SidenavComponent],
})
export class ContentModule {}
