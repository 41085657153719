import { NgModule } from '@angular/core';

import {
  NzButtonModule,
  NzCardModule,
  NzCarouselModule,
  NzCheckboxModule,
  NzCollapseModule,
  NzFormModule,
  NzIconModule,
  NzMenuModule,
  NzModalModule,
  NzRadioModule,
  NzSkeletonModule,
  NzTabsModule,
  NzTableModule,
  NzSelectModule
} from 'ng-zorro-antd';

@NgModule({
  exports: [
    NzButtonModule,
    NzCardModule,
    NzCarouselModule,
    NzCheckboxModule,
    NzCollapseModule,
    NzFormModule,
    NzIconModule,
    NzMenuModule,
    NzModalModule,
    NzRadioModule,
    NzSkeletonModule,
    NzTabsModule,
    NzTableModule,
    NzSelectModule
  ],
})
export class NgZorroModule {}
