export * from './autoridades-layout/autoridades-layout.module';
export * from './contacto-layout/contacto-layout.module';
export * from './generico-layout/generico-layout.module';
export * from './transparencia-layout/transparencia-layout.module';
export * from './layout/layout.component';
export * from './lista-noticias-layout/lista-noticias-layout.module';
export * from './lista-programas-layout/lista-programas-layout.module';
export * from './lista-revistas-layout/lista-revistas-layout.module';
export * from './lista-servicios-layout/lista-servicios-layout.module';
export * from './lista-tesis-layout/lista-tesis-layout.module';
export * from './noticia-layout/noticia-layout.module';
export * from './programa-layout/programa-layout.module';
export * from './programas-convocados/programas-convocados-layout.module';

export * from './sidenav/sidenav.component';
