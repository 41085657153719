import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { firstValueFrom } from 'rxjs';

import { SendMailContactoFormRequest } from '@core/form-requests';

@Injectable()
export class ContactoHttpService {
  constructor(private httpClient: HttpClient) {}

  async sendMail(body: SendMailContactoFormRequest): Promise<void> {
    const url = 'api/contacto';
    const httpCall = this.httpClient.post<void>(url, body);

    return await firstValueFrom(httpCall);
  }
}
