import { Component } from '@angular/core';

import { MenuComponent } from '@core/components';
import { NavService } from '@core/services';

@Component({
  selector: 'espg-mobile-menu',
  templateUrl: './mobile-menu.component.html',
  styleUrls: ['./mobile-menu.component.scss'],
})
export class MobileMenuComponent extends MenuComponent {
  constructor(public override navService: NavService) {
    super(navService);
  }

  close() {
    this.navService.triggerShowMobileMenu$(false);
  }
}
