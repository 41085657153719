import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { SwiperModule } from 'swiper/angular';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

import { BannerComponent, SectionComponent } from '@shared/components';
import { NgZorroModule } from '@shared/third-party';

@NgModule({
  imports: [],
  exports: [
    BannerComponent,
    SectionComponent,
    CommonModule,
    FontAwesomeModule,
    NgZorroModule,
    ReactiveFormsModule,
    RouterModule,
    SwiperModule,
  ],
  declarations: [BannerComponent, SectionComponent],
  providers: [],
})
export class SharedModule {}
