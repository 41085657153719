import { NgModule } from '@angular/core';

import { FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { fas } from '@fortawesome/free-solid-svg-icons';

import { SharedModule } from '@shared/shared.module';
import { HomePage } from './home.page';
import { TabAdmisionComponent } from './components/tab-admision/tab-admision.component';
import { TabDestacadosComponent } from './components/tab-destacados/tab-destacados.component';
import { TabServiciosComponent } from './components/tab-servicios/tab-servicios.component';
import { TabInvestigacionComponent } from './components/tab-investigacion/tab-investigacion.component';
import { TabAcademicoComponent } from './components/tab-academico/tab-academico.component';
import { TabProgramasComponent } from './components/tab-programas/tab-programas.component';

@NgModule({
  declarations: [HomePage, TabAdmisionComponent, TabDestacadosComponent, TabServiciosComponent, TabInvestigacionComponent, TabAcademicoComponent, TabProgramasComponent],
  imports: [SharedModule],
  exports: [HomePage],
})
export class HomeModule {
  constructor(library: FaIconLibrary) {
    library.addIconPacks(fas);
  }
}
