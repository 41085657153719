import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ElementoMenu, SuccessResponse } from '@core/models';
import { firstValueFrom } from 'rxjs';

@Injectable()
export class PaginaHttpService {
  constructor(private readonly httpClient: HttpClient) {}

  public async getMenu(paginaId: number): Promise<ElementoMenu[]> {
    const url = `api/paginas/${paginaId}/menu`;
    const httpCall = this.httpClient.get<SuccessResponse<ElementoMenu[]>>(url);
    const response = await firstValueFrom(httpCall);

    return response.data;
  }
}
