import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'espg-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.scss'],
})
export class BannerComponent implements OnInit {
  @Input('title')
  public title: string | undefined;

  @Input('image')
  public image: string | undefined;

  constructor() {}

  ngOnInit() {
    if (this.image) {
      this.setBackgroundImage();
    }
  }

  private setBackgroundImage(): void {
    const wrapper = document.querySelector('.banner__wrapper');
    const styleForWrapper = `background-image: url("${this.image}")`;

    if (!wrapper) {
      return;
    }

    wrapper.setAttribute('style', styleForWrapper);
    wrapper.classList.add('banner__background-image');
  }
}
