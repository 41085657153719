<espg-banner [title]="etiqueta| titlecase "></espg-banner>
<section>
    <div class="container">
        <h2 class="mt-3">Enlaces sugeridos para {{etiqueta}}</h2>
        <div class="card-container">
            <ng-container  *ngFor="let enlace of enlaces">
                
                <a *ngIf="!enlace.url" [routerLink]="setPath(enlace.redireccion)">
                <nz-card class="card link" *ngIf="!enlace.url" [routerLink]="setPath(enlace.redireccion)">
                    <nz-card-meta [nzTitle]="enlace.titulo"> </nz-card-meta>
                </nz-card>
                </a>

                <a *ngIf="enlace.url" [href]="enlace.url" class="link">
                    <nz-card class="card"  >
                        <nz-card-meta [nzTitle]="enlace.titulo"> </nz-card-meta>
                    </nz-card>
                </a>
            </ng-container>
        </div>
    </div>
</section>
