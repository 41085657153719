import { NgModule } from '@angular/core';

import { SharedModule } from '@shared/shared.module';
import { ProgramaLayoutComponent } from './programa-layout.component';

@NgModule({
  imports: [SharedModule],
  exports: [ProgramaLayoutComponent],
  declarations: [ProgramaLayoutComponent],
  providers: [],
})
export class ProgramaLayoutModule {}
