<div class="template--lista-programa">
  <div class="card-container">

    
    <ng-container  *ngFor="let programa of programas" >

    <nz-card class="card"  *ngIf="!programa.imagen"   >
      <nz-card-meta
        [nzTitle]="programa.nombre"
        [nzDescription]="tplDescription"
      >
      </nz-card-meta>

      <ng-template #tplDescription>
        <a [routerLink]="[programa.slug]" nz-button nzType="link">Mas información</a>
      </ng-template>

    </nz-card>


      <nz-card class="card" [nzCover]="coverTemplate" *ngIf="programa.imagen"  > 
          <nz-card-meta
            [nzTitle]="programa.nombre"
            [nzDescription]="tplDescription"
          >
          </nz-card-meta>
    
          <ng-template #tplDescription>
            <a [routerLink]="[programa.slug]" nz-button nzType="link">Mas información</a>
          </ng-template>
          <ng-template #coverTemplate>
            <img [alt]="programa.slug" [src]="programa.imagen" />
          </ng-template>
      </nz-card>
      
    </ng-container>   
  </div>
</div>


