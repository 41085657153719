import { NgModule } from '@angular/core';

import { SharedModule } from '@shared/shared.module';
import { AutoridadesLayoutComponent } from './autoridades-layout.component';

@NgModule({
  imports: [SharedModule],
  exports: [AutoridadesLayoutComponent],
  declarations: [AutoridadesLayoutComponent],
  providers: [],
})
export class AutoridadesLayoutModule {}
