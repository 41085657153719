<espg-section>
  <nz-tabset>
    <nz-tab [nzTitle]="tipo.nombre" *ngFor="let tipo of tiposDeRevista | async">
      <div class="card-container">
        <nz-card
          class="card"
          [nzCover]="tplPhoto"
          *ngFor="let revista of tipo.revistas"
        >
          <nz-card-meta
            [nzTitle]="revista.titulo"
            [nzDescription]="tplDescription"
          >
          </nz-card-meta>

          <ng-template #tplDescription>
            <p>{{ "Año " + revista.anio + " - N° " + revista.numero }}</p>

            <a [href]="revista.archivo" target="_blank" class="link">Leer</a>
          </ng-template>

          <ng-template #tplPhoto>
            <img
              [src]="revista.imagen"
              [alt]="revista.titulo"
              class="card-picture"
              onerror="setSourceImageOnError(e, 'assets/img/no-image.png')"
            />
          </ng-template>
        </nz-card>
      </div>
    </nz-tab>
  </nz-tabset>
</espg-section>
