import { ElementoMenu } from '@core/models';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'espg-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss'],
})
export class SidenavComponent {
  @Input()
  public items!: ElementoMenu[];

  public setLink(url: string) {
    return `/${url}`;
  }
}
