<div class="card-container">
  <nz-card class="card">
    <nz-card-meta
      nzTitle="Obtención del grado académico"
      [nzDescription]="tplDescription"
    >
    </nz-card-meta>

    <ng-template #tplDescription>
      <p>
        Conozca sobre las fases del procedimiento para la obtención del grado
        académico
      </p>
      <a
        nz-button
        nzType="link"
        [routerLink]="['fase-i-inscripcion-tema-tesis-y-asignacion-asesor']"
        >Ver mas</a
      >
    </ng-template>
  </nz-card>
  <nz-card class="card">
    <nz-card-meta
      nzTitle="Lineas de Investigación"
      [nzDescription]="tplDescription"
    >
    </nz-card-meta>

    <ng-template #tplDescription>
      <p>
        Las Líneas de investigación de la ESPG de la Universidad privada de
        Tacna se constituyen en un referente conductor de las propuestas
        alineadas al plan estratégico.
      </p>
      <a nz-button nzType="link" [routerLink]="['lineas-de-investigacion']"
        >Ver mas</a
      >
    </ng-template>
  </nz-card>

  <nz-card class="card">
    <nz-card-meta
      nzTitle="Esquemas, formatos y formalidades"
      [nzDescription]="tplDescription"
    >
    </nz-card-meta>

    <ng-template #tplDescription>
      <p>
        Conozca y descarga los distintos esquemas, formatos y formalidades a
        tener en cuenta para el procedimiento de obtención del grado académico
      </p>
      <a
        nz-button
        nzType="link"
        [routerLink]="['formatos']"
        >Ver mas</a
      >
    </ng-template>
  </nz-card>
</div>
