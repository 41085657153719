import { NavService } from '@core/services';
import { Component, OnInit } from '@angular/core';
import { Servicio } from '@core/models';
import { ServicioHttpService } from '@core/http';

@Component({
  selector: 'espg-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  public servicios: Promise<Servicio[]>;

  constructor(
    private readonly servicioHttpService: ServicioHttpService,
    private readonly navService: NavService
  ) {
    this.servicios = this.getServicios();
  }

  ngOnInit(): void {}

  private getServicios() {
    return this.servicioHttpService.getServicios();
  }

  openMobileMenu() {
    this.navService.triggerShowMobileMenu$(true);
  }
}
