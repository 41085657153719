import { NgModule } from '@angular/core';
import { GoogleMapsModule } from '@angular/google-maps';

import { SharedModule } from '@shared/shared.module';
import { ContactoLayoutComponent } from './contacto-layout.component';

@NgModule({
  imports: [GoogleMapsModule, SharedModule],
  exports: [ContactoLayoutComponent],
  declarations: [ContactoLayoutComponent],
})
export class ContactoLayoutModule {}
