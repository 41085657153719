import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { firstValueFrom } from 'rxjs';

import { SuccessResponse, TipoAutoridad } from '@core/models';

@Injectable()
export class AutoridadHttpService {
  constructor(private httpClient: HttpClient) {}

  async getTiposAutoridades(...include: string[]): Promise<TipoAutoridad[]> {
    let url = 'api/autoridades/tipos';
    let queryParams = new HttpParams();

    if (include) {
      queryParams = queryParams.set('include', include.join(','));
      url += `?${queryParams.toString()}`;
    }

    const httpCall = this.httpClient.get<SuccessResponse<TipoAutoridad[]>>(url);
    const response = await firstValueFrom(httpCall);

    return response.data;
  }
}
