export function getSlugSegment(url: string) {
  const splitUrl = url.split('?');
  if (splitUrl.length === 0) {
    return '';
  }

  const urlWithDashes = splitUrl[0];
  const splitUrlWithDashes = urlWithDashes.split('/');
  if (splitUrlWithDashes.length === 0) {
    return '';
  }

  const slug = splitUrlWithDashes.pop();
  if (!slug) {
    return '';
  }

  return slug;
}
