import { NgModule } from '@angular/core';

import { SharedModule } from '@shared/shared.module';
import { TransparenciaLayoutComponent } from './transparencia-layout.component';

@NgModule({
  imports: [SharedModule],
  exports: [TransparenciaLayoutComponent],
  declarations: [TransparenciaLayoutComponent],
})
export class TransparenciaLayoutModule {}
