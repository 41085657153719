import { Component, OnInit } from '@angular/core';

import { ProgramaHttpService } from '@core/http';
import { Programa } from '@core/models';

@Component({
  selector: 'espg-tab-programas',
  templateUrl: './tab-programas.component.html',
  styleUrls: ['./tab-programas.component.scss'],
})
export class TabProgramasComponent implements OnInit {
  public programas: Promise<Programa[]>;

  constructor(private readonly programaHttpService: ProgramaHttpService) {
    this.programas = this.programaHttpService.getAllProgramas(4, true, null);
  }

  ngOnInit(): void {}
}
