import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';

import {
  DesktopMenuComponent,
  HeaderComponent,
  FooterComponent,
  MobileMenuComponent,
} from '@core/components';
import { http, resolvers, services, interceptors } from '@core/core.providers';
import { SharedModule } from '@shared/shared.module';

@NgModule({
  declarations: [
    DesktopMenuComponent,
    FooterComponent,
    HeaderComponent,
    MobileMenuComponent,
  ],
  imports: [CommonModule, HttpClientModule, RouterModule, SharedModule],
  providers: [...http, ...resolvers, ...services, ...interceptors],
  // providers: [...http, ...resolvers, ...services, ],
  exports: [
    DesktopMenuComponent,
    FooterComponent,
    HeaderComponent,
    MobileMenuComponent,
  ],
})
export class CoreModule {}
