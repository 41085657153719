import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { firstValueFrom } from 'rxjs';

import { Noticia, SuccessResponse } from '@core/models';

@Injectable()
export class NoticiaHttpService {
  constructor(private httpClient: HttpClient) {}

  async getNoticias(limit?: number): Promise<Noticia[]> {
    let queryParams = new HttpParams();

    if (limit) {
      queryParams = queryParams.set('limit', limit);
    }

    let url = 'api/noticias';
    if (queryParams.getAll.length > 0) {
      url += `?${queryParams.toString()}`;
    }
    const httpCall = this.httpClient.get<SuccessResponse<Noticia[]>>(url);
    const response = await firstValueFrom(httpCall);

    return response.data;
  }

  
  async getNoticiasDetacadas(limit?: number): Promise<Noticia[]> {
    let queryParams = new HttpParams();

    if (limit) {
      queryParams = queryParams.set('limit', limit);
    }

    let url = 'api/noticias-destacadas';
    if (queryParams.getAll.length > 0) {
      url += `?${queryParams.toString()}`;
    }
    const httpCall = this.httpClient.get<SuccessResponse<Noticia[]>>(url);
    const response = await firstValueFrom(httpCall);

    return response.data;
  }


  async getNoticiaBySlug(slug: string): Promise<Noticia> {
    const url = `api/noticia/${slug}`;
    const httpCall = this.httpClient.get<SuccessResponse<Noticia>>(url);
    const response = await firstValueFrom(httpCall);

    return response.data;
  }
}
