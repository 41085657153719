<div class="home">
  <section class="m-0 w-full mb-1">
    <img class="w-full banner-image object-fill" src="{{ imagen }}" alt="" />
  </section>

  <div class="home__section bg-white">
    <div class="quick-access w-full">
      <nz-tabset class="quick-access__tabset" nzCentered>
        <nz-tab [nzTitle]="tabDestacados">
          <ng-template #tabDestacados>
            <ng-container
              *ngTemplateOutlet="tplTab; context: { $implicit: {title: 'Eventos', iconClass:'star'} }"
            ></ng-container>
          </ng-template>
          <espg-tab-destacados></espg-tab-destacados>
        </nz-tab>
        
        <nz-tab [nzTitle]="tabAdmision">
          <ng-template #tabAdmision>
            <ng-container
              *ngTemplateOutlet="tplTab; context: { $implicit: {title: 'Admisión', iconClass:'check-square'} }"
            ></ng-container>
          </ng-template>
          <espg-tab-admision></espg-tab-admision>
        </nz-tab>

        <nz-tab [nzTitle]="tabProgramas">
          <ng-template #tabProgramas>
            <ng-container
              *ngTemplateOutlet="tplTab; context: { $implicit: {title: 'Programas', iconClass:'graduation-cap'} }"
            ></ng-container>
          </ng-template>
          <espg-tab-programas></espg-tab-programas>
        </nz-tab>

        <nz-tab [nzTitle]="tabAcademico">
          <ng-template #tabAcademico>
            <ng-container
              *ngTemplateOutlet="tplTab; context: { $implicit: {title: 'Académico', iconClass:'university'} }"
            ></ng-container>
          </ng-template>
          <espg-tab-academico></espg-tab-academico>
        </nz-tab>

        <nz-tab [nzTitle]="tabInvestigacion">
          <ng-template #tabInvestigacion>
            <ng-container
              *ngTemplateOutlet="tplTab; context: { $implicit: {title: 'Investigación', iconClass:'microscope'} }"
            ></ng-container>
          </ng-template>
          <espg-tab-investigacion></espg-tab-investigacion>
        </nz-tab>

        <nz-tab [nzTitle]="tabServicios">
          <ng-template #tabServicios>
            <ng-container
              *ngTemplateOutlet="tplTab; context: { $implicit: {title: 'Servicios', iconClass:'hand-holding'} }"
            ></ng-container>
          </ng-template>
          <espg-tab-servicios></espg-tab-servicios>
        </nz-tab>          
      </nz-tabset>
    </div>
  </div>
</div>


<nz-modal
[(nzVisible)]="isVisible"
[nzTitle]="modalTitle"
[nzContent]="modalContent"
[nzFooter]="modalFooter"
(nzOnCancel)="handleCancel()"
>
<ng-template #modalTitle>AVISOS IMPORTANTES</ng-template>

<ng-template #modalContent>

  <swiper [navigation]="true" [loop]="true" [autoplay]="true">
    <ng-template swiperSlide *ngFor="let noticia of noticias">
      <a href="{{noticia.enlace_externo}}" target="_blank"><picture>
        <source
          srcset="{{ noticia.imagen }}"
        />
        <img
          src="{{ noticia.titulo }}"
          alt=""
        />
      </picture></a>
    </ng-template>
  </swiper>
</ng-template>

<ng-template #modalFooter>
 
</ng-template>
</nz-modal>

<ng-template #tplTab let-tabproperties>
  <div class="tab__wrapper">
    <fa-icon [icon]="['fas', tabproperties.iconClass]" size="2x"></fa-icon>
    <strong>{{ tabproperties.title}}</strong>
  </div>
</ng-template>

<div class="text-center pt-8 pb-8 bg-grey-background">
  <div class="follow-us container">
    <ul class="follow-us__social-networks social-networks">
      <li
      
        class="social-networks__item flex justify-center"
      >
        <a href="https://api.whatsapp.com/send?phone=51943316372&text=%C2%A1Hola!%20Quiero%20m%C3%A1s%20informaci%C3%B3n%20sobre%20Postgrado%20UPT.%20Mi%20consulta%20es%E2%80%A6" target="_blank" >
          <img
            src="assets/img/icons/whatsapp.svg"
           alt="whatsapp"
            class="social-networks__item__icon "
          />
        </a>
      </li>
    </ul>
  </div>
</div>
