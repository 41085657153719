import { NgModule } from '@angular/core';

import { SharedModule } from '@shared/shared.module';
import { ListaNoticiasLayoutComponent } from './lista-noticias-layout.component';

@NgModule({
  imports: [SharedModule],
  exports: [ListaNoticiasLayoutComponent],
  declarations: [ListaNoticiasLayoutComponent],
})
export class ListaNoticiasLayoutModule {}
