<ul class="desktop-menu menu menu--root">
  <li
    class="menu__item"
    *ngFor="let item of items"
    (mouseenter)="openSubmenu($event)"
    (mouseleave)="closeSubmenu($event)"
  >
    <span *ngIf="item.tipo === 'Encabezado'">
      {{ item.titulo }}
    </span>

    <a
      *ngIf="item.tipo === 'Pagina'"
      [routerLink]="setPath(item.redireccion)"
      class="menu__item--page"
    >
      {{ item.titulo }}</a
    >

    <a *ngIf="item.tipo === 'Enlace'" [href]="item.redireccion">{{
      item.titulo
    }}</a>

  </li>
</ul>