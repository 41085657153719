import { Injectable } from '@angular/core';

import { Subject } from 'rxjs';

import { ScreenSize } from '@core/constants';

@Injectable()
export class ResizeService {
  private resizeSubject: Subject<ScreenSize>;

  constructor() {
    this.resizeSubject = new Subject();
  }

  get resize$() {
    return this.resizeSubject.asObservable();
  }

  triggerResize() {
    const screenSize = this.checkResizing();
    this.resizeSubject.next(screenSize);
  }

  private checkResizing(): ScreenSize {
    const widthScreen = window.innerWidth;
    let screenSize: ScreenSize;

    switch (true) {
      case widthScreen < 640:
        screenSize = ScreenSize.XS;
        break;

      case widthScreen >= 640 && widthScreen < 768:
        screenSize = ScreenSize.SM;
        break;

      case widthScreen >= 768 && widthScreen < 1024:
        screenSize = ScreenSize.MD;
        break;

      case widthScreen >= 1024 && widthScreen < 1200:
        screenSize = ScreenSize.LG;
        break;

      default:
        screenSize = ScreenSize.XL;
        break;
    }

    return screenSize;
  }
}
