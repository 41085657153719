import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { NoticiaHttpService } from '@core/http';
import { Noticia } from '@core/models';
import { getSlugSegment } from '@shared/util';
import * as moment from 'moment';

@Component({
  selector: 'espg-noticia-layout',
  templateUrl: './noticia-layout.component.html',
  styleUrls: ['./noticia-layout.component.scss'],
})
export class NoticiaLayoutComponent implements OnInit {
  public noticia: Noticia | undefined;

  constructor(
    private readonly noticiaHttpService: NoticiaHttpService,
    private readonly route: Router
  ) {}

  ngOnInit(): void {
    this.getNoticia();
  }

  private getNoticia() {
    const slug = getSlugSegment(this.route.url);
    this.noticiaHttpService
      .getNoticiaBySlug(slug)
      .then((response) => (this.noticia = response));
  }


  

  fncFormatFechaHora(date: any){
    return moment(date).utc().format("YYYY-MM-DDTHH:mm")
  }
}
