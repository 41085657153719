export enum ContentLayout {
  GENERICO = 1,
  AUTORIDADES = 2,
  TRANSPARENCIA = 3,
  LISTA_PROGRAMAS = 4,
  PROGRAMA = 5,
  SERVICIOS = 6,
  LISTA_NOTICIAS = 7,
  NOTICIA = 8,
  LISTA_REVISTAS = 9,
  CONTACTO = 10,
  LISTA_TESIS = 11,
}
