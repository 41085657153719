import { NgModule } from '@angular/core';

import { SharedModule } from '@shared/shared.module';
import { ListaTesisLayoutComponent } from './lista-tesis-layout.component';

@NgModule({
  imports: [SharedModule],
  exports: [ListaTesisLayoutComponent],
  declarations: [ListaTesisLayoutComponent],
})
export class ListaTesisLayoutModule {}
