import { Component, OnInit } from '@angular/core';
import { AutoridadHttpService } from '@core/http';
import { TipoAutoridad } from '@core/models';

@Component({
  selector: 'espg-autoridades-layout',
  templateUrl: './autoridades-layout.component.html',
  styleUrls: ['./autoridades-layout.component.scss'],
})
export class AutoridadesLayoutComponent {
  public tiposDeAutoridades: Promise<TipoAutoridad[]>;

  constructor(private readonly autoridadHttpService: AutoridadHttpService) {
    this.tiposDeAutoridades =
      this.autoridadHttpService.getTiposAutoridades('autoridades');
  }

  public setCardTitle(abbr: string, fullName: string) {
    return abbr.concat(' ', fullName);
  }
}
