<espg-banner [title]="pageData?.banner?.titulo"></espg-banner>

<div class="content-page__container">
  <espg-sidenav
    class="content-page__sidenav"
    *ngIf="enlaces.length > 0"
    [items]="enlaces"
  ></espg-sidenav>

  <espg-content-layout class="content-page__body"></espg-content-layout>
</div>
