import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { firstValueFrom } from 'rxjs';

import { Servicio, SuccessResponse } from '@core/models';

@Injectable()
export class ServicioHttpService {
  constructor(private httpClient: HttpClient) {}

  async getServicios(limit?: number): Promise<Servicio[]> {
    let queryParams = new HttpParams();

    if (limit) {
      queryParams = queryParams.set('limit', limit);
    }

    let url = 'api/servicios';
    if (queryParams.getAll.length > 0) {
      url += `?${queryParams.toString()}`;
    }
    const httpCall = this.httpClient.get<SuccessResponse<Servicio[]>>(url);
    const response = await firstValueFrom(httpCall);

    return response.data;
  }
}
