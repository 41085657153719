<div class="tab-content--admision">
  <div class="tab-content__body body-admision">
    <img
      src="assets/img/admision/admision_1.jpg"
      class="body-admision__picture"
      alt="admision"
    />

    <div class="body-admision__description">
      <h2 class="text-espg-cobalt-blue text-center">Proceso de Admisión {{procesoAdmisionActivo}}</h2>
      <p>{{descripcionProcesoAdmision}}</p>

      <div class="tab-content__actions">
        <div class="actions__button">
          <a
            routerLink="/programas-convocados"
            class="actions__button"
            nzType="primary"
            nz-button
            >Programas Convocados</a
          >
          <a
            routerLink="/guia-admision"
            class="actions__button"
            nzType="primary"
            nz-button
            >Guía de Admisión</a
          >
        </div>
      </div>
    </div>
  </div>
</div>
