import { NgModule } from '@angular/core';

import { SharedModule } from '@shared/shared.module';
import { ListaRevistasLayoutComponent } from './lista-revistas-layout.component';

@NgModule({
  imports: [SharedModule],
  exports: [ListaRevistasLayoutComponent],
  declarations: [ListaRevistasLayoutComponent],
  providers: [],
})
export class ListaRevistasLayoutModule {}
