import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

import Swal from 'sweetalert2';

import { SendMailContactoFormRequest } from '@core/form-requests';
import { ContactoHttpService, ProgramaHttpService } from '@core/http';
import { MapInfoWindow, MapMarker } from '@angular/google-maps';
import { Programa } from '@core/models';
@Component({
  selector: 'espg-contacto-layout',
  templateUrl: './contacto-layout.component.html',
  styleUrls: ['./contacto-layout.component.scss'],
})
export class ContactoLayoutComponent implements OnInit {
  public contactForm!: FormGroup;
  public locationCoordinates: google.maps.LatLng;
  public description: string;
  public programas: Programa[] | undefined;
  public departamentosPeru = [
    "Amazonas",
    "Áncash",
    "Apurímac",
    "Arequipa",
    "Ayacucho",
    "Cajamarca",
    "Callao",
    "Cusco",
    "Huancavelica",
    "Huánuco",
    "Ica",
    "Junín",
    "La Libertad",
    "Lambayeque",
    "Lima",
    "Loreto",
    "Madre de Dios",
    "Moquegua",
    "Pasco",
    "Piura",
    "Puno",
    "San Martín",
    "Tacna",
    "Tumbes",
    "Ucayali",
    "Exterior" // Opción para el exterior
];
  

  @ViewChild(MapInfoWindow) infoWindow!: MapInfoWindow;


  constructor(
    private readonly formBuilder: FormBuilder,
    private readonly programaHttpService: ProgramaHttpService,
    private readonly contactoHttpService: ContactoHttpService,    
  ) {
    this.locationCoordinates = new google.maps.LatLng({
      lat: -18.005156,
      lng: -70.23511,
    });

    this.description = "ESPG UPT";
  }

  ngOnInit(): void {
    this.buildForm();
    this.getProgramas();
  }

  selectValidator(control: FormControl): { [key: string]: boolean } | null {
    return control.value === 'S' ? { 'required': true } : null;
  }

  private getProgramas() {
    this.programaHttpService
      .getAllProgramas(null, true, null)
      .then((data) => {
        return this.programas = data.filter(function(element){
          return !element.nombre.toLocaleLowerCase().includes('semipresencial');
        }).map((item) => {
          item.nombre =  item.nombre.replace("Presencial", "").trim();
          item.nombre =  item.nombre.replace("-", "").trim();
          return item
        });
      });
  }

  private buildForm() {
    this.contactForm = this.formBuilder.group({
      nombres: [
        '',
        [
          Validators.required,
          Validators.minLength(2),
          Validators.maxLength(100),
        ],
      ],
      tipodocumento: [
        'S',
        [
          Validators.required,          
          this.selectValidator,
        ],
      ],
      programa: [
        'S',
        [
          Validators.required,  
          this.selectValidator,
        ],
      ],
      apellidos: [
        '',
        [
          Validators.required,
          Validators.minLength(2),
          Validators.maxLength(100),
        ],
      ],
      direccion: [
        '----',
        [
          Validators.required,
          Validators.minLength(2),
          Validators.maxLength(250),
        ],
      ],
      email: [
        '',
        [
          Validators.email,
          Validators.required,
          Validators.minLength(2),
          Validators.maxLength(250),
        ],
      ],
      telefono: [
        '',
        [
          Validators.required,
          Validators.minLength(9),
          Validators.maxLength(20),
        ],
      ],
      documento_identidad: [
        '',
        [
          Validators.required,
          Validators.minLength(8),
          Validators.maxLength(20),
        ],
      ],
      mensaje: [
        '',
        [],
      ],
      aceptar_acuerdo: [false, [Validators.requiredTrue]],
      departamento: [
        'S',
        [
          Validators.required,          
          this.selectValidator,
        ],
      ],
    });
  }

  public sendMessage() {
    if (this.contactForm.invalid) {


      Object.values(this.contactForm.controls).forEach(control => {
        if (control.invalid) {
          control.markAsDirty();
          control.updateValueAndValidity({ onlySelf: true });
        }
      });
      return;
    }

    const requestBody = this.setRequestBody();

    try {
      this.contactoHttpService.sendMail(requestBody);
      Swal.fire(
        'Su mensaje fue enviado',
        'Gracias por tus comentarios',
        'success'
      );
      this.contactForm.reset();
    } catch (error) {
      Swal.fire(
        'Ocurrió un error',
        'Lamentamos los inconvenientes. Intentalo de nuevo mas tarde.',
        'error'
      );
    }
  }

  private setRequestBody(): SendMailContactoFormRequest {
    return {
      nombres: this.contactForm.value['nombres'],
      programa: this.contactForm.value['programa'],
      apellidos: this.contactForm.value['apellidos'],
      direccion:  this.contactForm.value['departamento'],
      correo: this.contactForm.value['email'],
      telefono: this.contactForm.value['telefono'],
      numero_documento_identidad: this.contactForm.value['tipodocumento'] + this.contactForm.value['documento_identidad'],
      mensaje: `Se ha registrado una solicitud de información con los siguientes departamentosPeru
        Programa de interés: ${this.contactForm.value['programa']},
        Número de documento: ${this.contactForm.value['tipodocumento'] + this.contactForm.value['documento_identidad']},
        Nombres: ${this.contactForm.value['nombres']},   
        Apellidos: ${this.contactForm.value['apellidos']},   
        Correo Electrónico: ${this.contactForm.value['email']},   
        Celular: ${this.contactForm.value['telefono']},   
        Departamento: ${this.contactForm.value['departamento']},
      `,
      acepto: this.contactForm.value['aceptar_acuerdo'],
    };
  }

  openInfoWindow(marker: MapMarker) {
    this.infoWindow.open(marker);
  }
}
