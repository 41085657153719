import { Component, OnInit } from '@angular/core';
import { ProgramaHttpService } from '@core/http';

@Component({
  selector: 'espg-tab-admision',
  templateUrl: './tab-admision.component.html',
  styleUrls: ['./tab-admision.component.scss'],
})
export class TabAdmisionComponent implements OnInit {
  
  public procesoAdmisionActivo: string = "";
  public descripcionProcesoAdmision: string = "";
  constructor(private readonly programaHttpService: ProgramaHttpService) {}

  ngOnInit(): void {

     this.programaHttpService.getProcesoAdmisionActivo().then(
      (data: any) => {
        this.procesoAdmisionActivo = data.Nombre;
        this.descripcionProcesoAdmision = data.Descripcion;
      }
    );

  }
}
