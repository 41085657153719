import { Component, OnInit } from '@angular/core';

import { NoticiaHttpService } from '@core/http';
import { Noticia } from '@core/models';

@Component({
  selector: 'espg-lista-noticias-layout',
  templateUrl: './lista-noticias-layout.component.html',
  styleUrls: ['./lista-noticias-layout.component.scss'],
})
export class ListaNoticiasLayoutComponent implements OnInit {
  public noticias: Promise<Noticia[]>;

  constructor(private readonly noticiaHttpService: NoticiaHttpService) {
    this.noticias = this.noticiaHttpService.getNoticias();
  }

  ngOnInit(): void {}
}
